<template>
  <div
    id="info"
    class="va-row"
  >
    <div class="flex md10 xs12">
      <vuestic-widget>
        <h2>Notas de Atualização</h2>
        <p class="text-center"> Última atualização: 06/05/2019 </p>
        <div class="va-row">
          <div class="flex md1" />
          <div class="flex md5 ">
            <h4>Funcionalidade Nova</h4>
            <p class="vuestic-alert alert alert-success flex md10 xs12 ">
              Adicionado tabela de ação por funcionário na dashboard
            </p>
            <p class="vuestic-alert alert alert-success flex md10 xs12">
              Adicionado tabela de ação por ferramenta na dashboard
            </p>
            <p class="vuestic-alert alert alert-success flex md10 xs12">
              Adicionado tabela de ação por área na dashboard
            </p>
            <p class="vuestic-alert alert alert-success flex md10 xs12">
              Tabela da ação (Botões removido da tabela e edição funcionando com duplo click)

            </p>
          </div>
          <div class="flex md1" />
          <div class="flex md5">
            <h4>Correções de Bugs</h4>
            <p class="vuestic-alert alert alert-info flex md10 xs12 ">
              Verificação do usuário logado

            </p>
            <p class="vuestic-alert alert alert-info flex md10 xs12 ">
              Carregamento das informações das ações otimizada

            </p>
          </div>
        </div>

        <hr>
        <div class="va-row">
          <div class="flex md1" />
          <div class="flex md5">
            <h4>Funcionalidade Removida </h4>
            <p class="vuestic-alert alert  alert-warning flex md10 xs12">
              Gráfico de pizza removido da dashboard

            </p>
          </div>

          <div class="flex md1" />
          <div class="flex md5">
            <h4> Funcionalidade Instável</h4>
            <p class="vuestic-alert alert  alert-danger flex md10 xs12">
              Listagem de relatos
            </p>

          </div>
        </div>
        <br> <br>
        <button
          class="btn btn-dark btn-micro"
          @click="$router.push({name:'login'})"
        >Sair</button>
      </vuestic-widget>
    </div>
  </div>
</template>
<script>
export default {

};
</script>
<style lang="scss" scoped>
#info {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}
#info hr {
  margin: 0%;
  padding: 0%;
}
#info h4 {
  text-align: center;
}
#info h3 {
  text-align: center;
}
h2,
h2 {
  margin-top: 0.5rem;
  text-align: center;
}
</style>
